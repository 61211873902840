const initialState = {
  listAllPelunasan: null,
  modalDetailPelunasan: true,
  detailPelunasan: null,
  urlDokumen: "",
};

const pelunasanReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_GET_LIST_PELUNASAN":
      return {
        ...state,
        listAllPelunasan: data,
      };
    case "SET_DETAIL_PELUNASAN":
      return {
        ...state,
        detailPelunasan: data,
      };
    case "SET_DETAIL_PENGAJUAN_PELUNASAN":
      return {
        ...state,
        modalDetailPelunasan: data,
      };
    case "SHOW_URL_DOKUMEN":
      return {
        ...state,
        urlDokumen: data,
      };
    default:
      return state;
  }
};

export default pelunasanReducer;
