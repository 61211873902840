import { baseAxios, errorHandler } from "../index";

export async function getListPelunasan(dispatch, data, history) {
  //   console.log(data, "DATAA");
  try {
    const response = await baseAxios.get(
      `webPelunasan/getListPelunasan?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_GET_LIST_PELUNASAN",
      data: response.data.data,
    });
    dispatch({
      type: "SET_DETAIL_PELUNASAN",
      data: response.data.data.docs,
    });
    // console.log(response, "RRRRRR");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function approvePelunasan(data) {
  try {
    const response = await baseAxios.post(
      "webPelunasan/approvalPelunasan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportPengajuanPelunasan(data) {
  // console.log(data, "DATAAAA");
  try {
    const response = await baseAxios.post(
      "webPelunasan/exportPelunasanExcel",
      data,
      {
        headers: { token: localStorage.getItem("token") },
        responseType: "blob",
      }
    );
    // console.log(response, "AAA");
    return response.data;
  } catch (err) {
    return err.response;
  }
}
export async function showUrlDokumen(dispatch, data, history) {
  try {
    const response = await baseAxios.post("webPelunasan/showUrlDokumen", data, {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({
      type: "SHOW_URL_DOKUMEN",
      data: response.data.url,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
